import styles from './BinaryDecoCommon.module.scss';
import React from 'react';

interface BinaryDecoProps {
    className?: string,
    lines: Array<string>
}

export default function BinaryDecoCommon({ className, lines }: BinaryDecoProps) {
    return (
        <>
            <div className={ `${ styles.box } ${ className }` }>
                { lines.map(value => <span key={ value }> { value } </span>) }
            </div>
        </>
    );
}