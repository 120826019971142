import Link from 'next/link';
import Image from 'next/image';
import { Col, Row } from 'antd';
import { useRouter } from 'next/router';
import { AnimatePresence } from 'framer-motion';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { faDiscord, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

import { Overlay } from './Overlay';
import styles from './MainLandingView.module.scss';
import DivBg from '../../common/DivWithBackground/DivBg';
import { StringUtils } from '../../../utils/StringUtils';
import { ParseCarbon } from '../../../utils/ParseCarbon';
import { NumberUtils } from '../../../utils/NumberUtils';
import MetaButton from '../../common/MetaButton/MetaButton';
import { HomePagePost } from '../../../API/EntityTypes/Blog';
import BasicContent from '../../common/BasicContent/BasicContent';
import AltMetaButton from '../../common/AltMetaButton/AltMetaButton';
import DecorationTypeA from '../../decorationElements/DecorationTypeA';
import WeirdContainer from '../../common/WeirdContainer/WeirdContainer';
import { FromStrapiButton } from '../../common/FromStrapiButton/FromStrapiButton';

export interface MainLandingViewProps {
    homeInfo: HomePagePost | null;
    gamesRef: React.MutableRefObject<HTMLDivElement>;
    carbon: {
        available: number;
        totalEarned: number;
        lockedInNFTs: number;
    }
}

export default function MainLandingView({
    carbon,
    gamesRef,
    homeInfo,
}: MainLandingViewProps) {
    const router = useRouter();
    const [ showOverlay, setShowOverlay ] = useState(false);
    const videoId = StringUtils.getYoutubeVideoId(homeInfo ? homeInfo.FeaturedVideo : 'https://www.youtube.com/watch?v=DuPPnGsrsMo&ab_channel=MyMetaverse');

    const nextMonth = useMemo(() => {
        const date = new Date();
        const date2 = new Date();

        date2.setMonth(date.getMonth() + 1);
        date2.setDate(1);

        return date2;
    }, []);

    const handleClose = () => {
        setShowOverlay(false);
    };

    const handleScroll = useCallback(() => {
        gamesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [ gamesRef ]);

    useEffect(() => {
        if (router.query.scrollGames) {
            handleScroll();
            delete router.query.scrollGames;
            router.replace(router, undefined, { shallow: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ handleScroll, router.query ]);

    return (
        <>
            <AnimatePresence>
                { showOverlay && <Overlay embedurl={ `https://www.youtube-nocookie.com/embed/${ videoId }` } onClose={ handleClose }/> }
            </AnimatePresence>
            <DivBg className={ `${ styles.wrapper }` } bgSrc={ '/bg.jpg' } imageProps={ {
                priority: true,
                quality: 10,
            } }>
                <div className={ `${ styles.content } containerPaddingTop` }>

                    <section className={ 'containerLength' }>
                        <DecorationTypeA />
                        <Row gutter={ [ 32, 32 ] } className={ styles.heroArea }>
                            <Col className={ styles.textSide } xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 } xl={ 12 }>
                                <h1 className={ 'up' }>
                                    PLAY NOW TO EARN
                                </h1>
                                <h2>
                                    {ParseCarbon.parse(carbon.available, true)} CARBON (${NumberUtils.niceNumber(ParseCarbon.carbonToDolars(carbon.available))})
                                </h2>
                                <div className={ styles.explanation }>
                                    <div className={ `${ styles.homeText } font14-lh` }>
                                        To be sent on the {NumberUtils.addOrdinalSuffix(nextMonth.getDate())} of {StringUtils.getMonthName(nextMonth.getMonth())}
                                    </div>
                                    <div className={ styles.explanation__footer }>
                                        {homeInfo.Buttons.length > 0
                                            ? homeInfo.Buttons.map(button => <FromStrapiButton key={ button.id } link={ button.link } text={ button.text } outlined={ button.outlined } />)
                                            : <>
                                                <MetaButton value='PLAY GAMES' onClick={ handleScroll } />
                                                <Link href="/nfts" passHref>
                                                    <a>
                                                        <AltMetaButton value='MINT NFTS' />
                                                    </a>
                                                </Link>
                                            </>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 } xl={ 12 }>
                                <div className={ styles.videoSide }>
                                    <WeirdContainer>
                                        <div className={ styles.videoIcon } onClick={ () => setShowOverlay(true) }>
                                            <FontAwesomeIcon icon={ faPlayCircle } />
                                        </div>
                                        <Image
                                            layout='fill'
                                            placeholder='blur'
                                            alt='Cover image for introductory blockchain video.'
                                            src={ `https://i3.ytimg.com/vi/${ videoId }/maxresdefault.jpg` }
                                            blurDataURL={ `http://i3.ytimg.com/vi/${ videoId }/hqdefault.jpg` }
                                        />
                                    </WeirdContainer>
                                </div>
                            </Col>
                        </Row>
                    </section>

                    <section className={ `${ styles.landingFooter } containerLength` }>
                        <Row gutter={ [ 32, 32 ] } className={ styles.footerTextSquare }>
                            <Col className={ styles.textSide } xs={ 24 } sm={ 24 } md={ 8 } lg={ 8 } xl={ 8 }>
                                <BasicContent
                                    title={ `${ ParseCarbon.parse(carbon.totalEarned, true) } CARBON ($${ NumberUtils.niceNumber(ParseCarbon.carbonToDolars(carbon.totalEarned)) })` }
                                    className={ styles.basicContent }
                                >
                                    Earned by players in total
                                </BasicContent>
                            </Col>
                            <Col className={ styles.textSide } xs={ 24 } sm={ 24 } md={ 8 } lg={ 8 } xl={ 8 }>
                                <BasicContent
                                    title={ `${ ParseCarbon.parse(carbon.lockedInNFTs, true) } OF CARBON` }
                                    className={ styles.basicContent }
                                >
                                    Locked inside NFTs
                                </BasicContent>
                            </Col>
                            <Col className={ styles.textSide } xs={ 24 } sm={ 24 } md={ 8 } lg={ 8 } xl={ 8 }> </Col>
                        </Row>
                        <div className={ styles.socialSquare }>
                            <div>
                                <a href='https://twitter.com/mymetaverse' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={ faTwitter } /></a>
                            </div>
                            <div>
                                <a href='https://www.youtube.com/mymetaverse' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={ faYoutube } /></a>
                            </div>
                            <div>
                                <a href='https://discord.gg/DJTzX83QnJ' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={ faDiscord } /></a>
                            </div>
                            <div>
                                <a href='https://t.me/MyMetaverse' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={ faTelegram } /></a>
                            </div>
                        </div>
                    </section>

                    <section className='containerLength'>
                        <svg
                            fill="none"
                            viewBox="0 0 1200 43"
                            onClick={ handleScroll }
                            style={ { cursor: 'pointer' } }
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 26.5H560L580 46.5H620L640 26.5L1200 26.5" stroke="white" strokeWidth="0.5" />
                            <line x1="585.177" y1="18.5276" x2="600.177" y2="33.5276" stroke="white" strokeWidth="0.5" />
                            <line x1="601.323" y1="32.0276" x2="614.823" y2="18.5276" stroke="white" strokeWidth="0.5" />
                            <line x1="589.288" y1="10.1382" x2="600.177" y2="21.0274" stroke="white" strokeWidth="0.5" />
                            <line x1="600.912" y1="19.9387" x2="610.712" y2="10.1383" stroke="white" strokeWidth="0.5" />
                            <line x1="600.25" y1="-1.36304e-05" x2="600.251" y2="11.2042" stroke="white" strokeWidth="0.5" />
                        </svg>
                    </section>

                </div>
            </DivBg>
        </>
    );
}
