

export class NumberUtils {

    public static niceNumber(number: string | number) {
        number = Number(number);
        return number.toLocaleString('en-US');
    }

    public static toDecimal(hex: string) : number {
        const value = parseInt(hex, 16);
        return Number.isNaN(value) ? -1 : value;
    }

    public static timeToFormat(time: number) {
        const second = 1000;
        const hour = 3600 * 1000;
        const minute = 60 * 1000;

        const hours = Math.floor(time / hour);
        const minutes = Math.floor((time - hours * hour) / minute);
        const seconds = Math.floor((time - hours * hour - minutes * minute) / second);

        return `${ hours }H ${ minutes }M ${ seconds }S`;
    }

    public static addOrdinalSuffix(n: number) {
        const s = [ 'th', 'st', 'nd', 'rd' ],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }
}
