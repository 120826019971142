import style from './AltMetaButton.module.scss';
import React, { ReactNode } from 'react';

interface MetaButtonProps {
    value?: string,
    className?: string,
    type?: 'submit' | 'reset' | 'button';
    // eslint-disable-next-line no-unused-vars
    onClick?: (ev: React.MouseEvent<HTMLDivElement>) => void,
    children?: ReactNode
}

export default function AltMetaButton({ value, type, className, onClick, children }: MetaButtonProps) {
    return (
        <div className={ `${ style.btnbox } ${ className }` } onClick={ event => {
            if (onClick)
                onClick(event);
        } }>
            <button className={ style.btn } type={ type }>
                {children || value || 'No value provided'}
            </button>
        </div>
    );
}
