import React from 'react';
import style from './WeirdContainer.module.scss';

export default function WeirdContainer({ children, className }: { children: React.ReactNode, className?: string }) {
    return (
        <>
            <div className={ `${ style.box } ${ className }` }>
                <div className={ style.mask }>
                    {children}
                </div>
            </div>
        </>
    );
}