import styles from './Overlay.module.scss';
import { motion } from 'framer-motion';

interface MetaButtonProps {
    embedurl: string,
    onClose?: any
}

export function Overlay({ embedurl, onClose }: MetaButtonProps) {

    return (
        <motion.div
            className={ styles.overlay }
            onClick={ onClose }
            initial={ { opacity: 0 } }
            animate={ { opacity: 1 } }
            exit={ { opacity: 0,
                transition: { duration: 0.15 } } }
        >
            <div className={ styles.video }>
                <motion.iframe src={ embedurl }
                    title='YouTube video player' frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                    className={ styles.videoIframe }
                    animate
                />
            </div>
        </motion.div>
    );
}