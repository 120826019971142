import Link from 'next/link';

import { useMemo } from 'react';
import MetaButton from '../MetaButton/MetaButton';
import { StringUtils } from '../../../utils/StringUtils';
import AltMetaButton from '../AltMetaButton/AltMetaButton';

interface FromStrapiButtonProps {
  text: string;
  link: string;
  outlined: boolean;
}

export function FromStrapiButton({
    text,
    link,
    outlined,
}: FromStrapiButtonProps) {
    const externalInfo = useMemo(() => StringUtils.isExternalURL(link), [ link ]);
    if (externalInfo === null) return null;

    return (
        externalInfo.isExternal
            ? <a href={ link } target="_blank" rel='noopener noreferrer'>
                {outlined ? <AltMetaButton value={ text } /> : <MetaButton value={ text } />}
            </a>
            : <Link href={ externalInfo.path } passHref>
                <a>
                    {outlined ? <AltMetaButton value={ text } /> : <MetaButton value={ text } />}
                </a>
            </Link>
    );
}