import styles from './BasicContent.module.scss';
import React from 'react';

export default function BasicContent({ className, title, children, titleClass }: {
    className?: string,
    children: React.ReactNode,
    title: string,
    titleClass?: string
}) {
    return (
        <div className={ `${ className } title` }>
            <div className={ `${ titleClass || 'font14' }` }>
                { title }
            </div>
            <div className={ styles.line } />
            <div className={ 'font12' }>
                { children }
            </div>
        </div>
    );
}