import React from 'react';
import styles from './DecorationTypeA.module.scss';
import BinaryDecoCommon from '../common/BinaryDecoCommon/BinaryDecoCommon';

export default function DecorationTypeA() {
    return (
        <>
            <div className={ styles.bgElements }>
                <BinaryDecoCommon className={ `${ styles.binaryBackground } ${ styles.a }` } lines={ [
                    '01010111011010010111010001101000001000000110111101',
                    '110101011100100010000001101100011011110111011001100101001011000',
                    '010000001110111011001010010000001100011011011110',
                    '11101010110110001100100001000000111001101100001011',
                    '1011001100101001000000111010001101000011001010010000001110111',
                    '01101111011100100110110001100100',
                ] }/>


                <BinaryDecoCommon className={ `${ styles.binaryBackground } ${ styles.b }` } lines={ [
                    '0100001101101111011001100110011001100',
                    '10101100101001000000110011001101001011100100',
                    '1110011011101000010111000100000010100110110000101110110',
                    '0110010100100000011101000110100001100101001000000',
                    '1110111011011110111001001101100011001000010',
                ] }/>

                <BinaryDecoCommon className={ `${ styles.binaryBackground } ${ styles.c }` } lines={ [
                    '000001101100011000010111',
                    '0100011001010111001001010011011011',
                    '01011000010111001001110100011',
                    '0111001100101011100110111001100',
                    '100000011101110110100101101100011011000010000001',
                    '1011100110111101110100001000000111001101100',
                    '001011101100110010100100000011101000110100001101001011',
                ] }/>

                <BinaryDecoCommon className={ `${ styles.binaryBackground } ${ styles.d }` } lines={ [
                    '1001100100000011101110110111101110010011011000110010000101100',
                    '0010000001110111011000010111001001101101011101000110100',
                    '0001000000110000101101110011001000010000001110111011010010111',
                    '0011011001000110111101101101001000000111011101',
                    '101001011011000110110000101110',
                ] }/>
            </div>
        </>
    );
}